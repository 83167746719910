import Pagination from "./Pagination";
import CropperImage from "@/components/CropperImage";
import CropperImageNew from "@/components/CropperImageNew";
export default {
	install(Vue) {
		Vue.component("Pagination", Pagination);
		Vue.component("CropperImage", CropperImage);
		Vue.component("CropperImageNew", CropperImageNew);
	},
};

import request from "@/utils/request";

// 全站数据
export function getAllpvuv(obj) {
	return request({
		url: "/admin/getAllpvuv",
		method: "post",
		data: obj,
	});
}

// 查询文章列表的数据
export function getWZListpvuv(obj) {
	return request({
		url: "/admin/getWZListpvuv",
		method: "post",
		data: obj,
	});
}
// 查询单篇文章的数据
export function getSingleWZpvuv(obj) {
	return request({
		url: "/admin/getSingleWZpvuv",
		method: "post",
		data: obj,
	});
}

// 导航栏tag
export function tabTag(obj) {
	return request({
		url: "/admin/information/tabTag",
		method: "post",
		data: obj,
	});
}

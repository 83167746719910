import request from "@/utils/request";

export function login(data) {
	return request({
		url: "/admin/login",
		method: "post",
		data,
	});
}

export function getInfo(obj) {
	return request({
		url: "/admin/getAdminInfo",
		method: "post",
		data: obj,
	});
}

export function logout() {
	return request({
		url: "/admin/logout",
		method: "post",
	});
}
//获取用户权限
export function getAuthMenu() {
	return request({
		url: "/admin/getPermissions",
		method: "post",
		data: { platform: 1 },
	});
}
// 上传图片

export function uploadImage(file) {
	return request({
		url: "/upload",
		method: "post",
		data: file,
	});
}
//   获取用户列表
export function getUsersList(obj) {
	return request({
		url: "/admin/users/lists",
		method: "post",
		data: obj,
	});
}
//   用户详情

export function getUsersdetails(obj) {
	return request({
		url: "/admin/users/details",
		method: "get",
		params: obj,
	});
}
//   新增用户

export function createusers(obj) {
	return request({
		url: "/admin/users/create",
		method: "post",
		data: obj,
	});
}

//   编辑用户

export function updateusers(obj) {
	return request({
		url: "/admin/users/update",
		method: "post",
		data: obj,
	});
}
//   删除用户
export function destroyusers(obj) {
	return request({
		url: "/admin/users/destroy",
		method: "post",
		data: obj,
	});
}
export function configInfo(obj) {
	return request({
		url: "/admin/configInfo",
		method: "get",
		params: obj,
	});
}
export function updateConfig(obj) {
	return request({
		url: "/admin/updateConfig",
		method: "post",
		data: obj,
	});
}
export function getPushAllList(obj) {
	return request({
		url: "/admin/push/getAllList",
		method: "post",
		data: obj,
	});
}
export function addPush(obj) {
	return request({
		url: "/admin/push/addPush",
		method: "post",
		data: obj,
	});
}

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import ElementUI from "element-ui";
import App from "./App";
import router from "./router";
import store from "./store";
import "element-ui/lib/theme-chalk/index.css";
import "./utils/flexible.js";
import "./permission.js"; // permission control
Vue.config.productionTip = false;
Vue.use(ElementUI);
import VueQuillEditor from "vue-quill-editor";
// require styles 导入富文本编辑器对应的样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor /* { default global options } */);
import Component from "@/components";
Vue.use(Component); // 注册自己的插件
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");

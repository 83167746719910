const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  user: state => state.user.user,
  name: state => state.user.name,
  roles: state => state.user.roles,
  levelList: state => state.user.levelList,
  permission_routes: state => state.permission.routes,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  menusRoutes:state => state.permission.routes,
}

export default getters

<template>
	<div class="login-container">
		<div class="loginImg">
			<!-- <img src="../../assets/images/login_logo.png" alt="" /> -->
		</div>
		<div class="bagBg boepoe" v-if="loginShow">
			<el-form
				ref="loginForm"
				:model="loginForm"
				:rules="loginRules"
				class="login-form"
				auto-complete="on"
				label-position="left"
			>
				<div class="title-container">
					<h3 class="title">登录</h3>
					<p class="colorKEOe">欢迎登录总后台</p>
				</div>

				<el-form-item prop="username">
					账号
					<span class="colornear">*</span>
					<el-input
						ref="username"
						v-model="loginForm.username"
						placeholder="请输入用户名"
						name="username"
						type="text"
						tabindex="1"
						auto-complete="on"
					/>
				</el-form-item>

				<el-form-item prop="password">
					密码
					<span class="colornear">*</span>
					<el-input
						:key="passwordType"
						ref="password"
						v-model="loginForm.password"
						:type="passwordType"
						placeholder="请输入密码"
						name="password"
						tabindex="2"
						auto-complete="on"
						@keyup.enter.native="handleLogin"
					/>
				</el-form-item>

				<div class="tips">
					<a class="colore" @click="loginShow = false">忘记密码?</a>
				</div>

				<el-button
					:loading="loading"
					type="primary"
					style="width: 100%; margin-bottom: 30px"
					class="submitBtn"
					@click.native.prevent="handleLogin"
					>登录</el-button
				>
			</el-form>
		</div>
		<!-- end忘记密码 -->
		<div class="bagBg bagBg1" v-else>
			<div class="forgetPadd">
				<div class="fLogin" @click="loginShow = true">返回登录?</div>
				<div class="title-container">
					<h3 class="title">忘记密码</h3>
					<p class="colorKEOe">欢迎登录总后台</p>
				</div>
				<el-form label-position="top" label-width="80px" :model="forgetFort">
					<el-form-item label="账号">
						<el-input
							v-model="forgetFort.mobile"
							placeholder="请输入账号"
						></el-input>
					</el-form-item>
					<el-form-item label="验证码">
						<el-input v-model="forgetFort.code" placeholder="请输验证码">
							<template slot="suffix">
								<el-button
									type="text"
									:disabled="disabled"
									@click="bindforgetSendCode"
									>{{ btnText }}</el-button
								>
							</template>
						</el-input>
					</el-form-item>
					<el-form-item label="新密码">
						<el-input
							v-model="forgetFort.password"
							placeholder="请输新密码"
						></el-input>
					</el-form-item>
					<el-form-item label="再次输入密码">
						<el-input
							v-model="forgetFort.password_confirm"
							placeholder="再次输入密码"
						></el-input>
					</el-form-item>
					<el-button
						:loading="loading"
						type="primary"
						style="width: 100%; margin-bottom: 30px"
						>确认</el-button
					>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
import { validUsername } from "@/utils/validate";

export default {
	name: "Login",
	data() {
		const validateUsername = (rule, value, callback) => {
			if (!validUsername(value)) {
				callback(new Error("请输入正确用户名"));
			} else {
				callback();
			}
		};
		const validatePassword = (rule, value, callback) => {
			if (value.length < 6) {
				callback(new Error("密码不能少于6位"));
			} else {
				callback();
			}
		};
		return {
			loginShow: true,
			btnText: "发送验证码",
			disabled: false,
			forgetFort: {
				mobile: "",
				code: "",
				password: "",
				password_confirm: "",
			},
			loginForm: {
				username: "",
				password: "",
				type: "1",
			},
			options: [
				{
					value: "1",
					label: "平台",
				},
			],
			loginRules: {
				username: [{ required: true, trigger: "blur" }],
				password: [{ required: true, trigger: "blur" }],
			},
			loading: false,
			passwordType: "password",
			redirect: undefined,
			isMobileDevice: false,
		};
	},
	watch: {
		$route: {
			handler: function (route) {
				this.redirect = route.query && route.query.redirect;
			},
			immediate: true,
		},
	},
	mounted() {
		var that = this;
		//刚进入页面时，获取窗口默认宽高度
		this.windowWidth = window.screen.width;
		this.isMobileDevice = this.windowWidth < 800;
		//根据屏幕缩放自动获取页面宽高
		window.onresize = () => {
			return (() => {
				//窗口缩放自动获取页面宽高
				window.fullWidth = window.screen.width;
				window.fullHeight = window.screen.height;
				that.windowWidth = window.fullWidth; //宽
				this.isMobileDevice = this.windowWidth < 800;
			})();
		};
	},
	methods: {
		bindforgetSendCode() {
			this.disabled = true;
			this.btnText = "请稍候...";
			setTimeout(() => {
				this.doLoop(60);
			}, 500);
		},
		doLoop(seconds) {
			seconds = seconds ? seconds : 60;
			this.btnText = seconds + "s后获取";
			this.code = code;
			let countdown = setInterval(() => {
				if (seconds > 0) {
					this.btnText = seconds + "s后获取";
					--seconds;
				} else {
					this.btnText = "获取验证码";
					this.disabled = false;
					clearInterval(countdown);
				}
			}, 1000);
		},
		ForgetPassword() {
			console.log(this.forgetFort);
		},
		showPwd() {
			if (this.passwordType === "password") {
				this.passwordType = "";
			} else {
				this.passwordType = "password";
			}
			this.$nextTick(() => {
				this.$refs.password.focus();
			});
		},
		handleLogin() {
			this.$refs.loginForm.validate((valid) => {
				if (valid) {
					this.loading = true;
					this.$store
						.dispatch("user/login", this.loginForm)
						.then(() => {
							if (this.isMobileDevice) {
								this.$router.push({ path: "/kuaixunphone" });
							} else {
								this.$router.push({ path: "/" });
							}
							this.loading = false;
						})
						.catch(() => {
							this.loading = false;
						});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		onSubmit() {
			console.log("submit!");
		},
	},
};
</script>

<style>
/* 修复input 背景不协调 和光标变色 */
/* Detail see https:github.com/PanJiaChen/vue-element-admin/pull/927 */

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
	.login-container .el-input input {
		color: #000 !important;
	}
}

/* reset element-ui css */
.login-container {
}
.login-container .el-input {
	display: inline-block;
	height: 47px;
	width: 85%;
}

.login-container .el-input input {
	background: transparent;
	border: 0px;
	-webkit-appearance: none;
	border-radius: 0px;
	padding: 12px 5px 12px 15px;
	color: gray;
	height: 47px;
	caret-color: #000;
}
.login-container .el-input input:-webkit-autofill {
	box-shadow: 0 0 0px 1000px inset !important;
	-webkit-text-fill-color: #000 !important;
}

.login-container .el-form-item {
	border: 1px solid rgba(255, 255, 255, 0.1);
	background: rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(22, 15, 83, 0.2);
	border-radius: 5px;
	color: #454545;
}
</style>

<style scoped>
.login-container {
	min-height: 100vh;
	width: 100%;
	background: url("../../assets/images/banner.png") no-repeat;
	background-size: 100% 100%;
	overflow: hidden;
	position: absolute;
}
.bagBg .login-form {
	position: relative;
	width: 400px;
	max-width: 100%;
	padding: 60px 60px 0;
	margin: 0 auto;
	overflow: hidden;
}

.tips {
	font-size: 14px;
	color: #fff;
	margin-bottom: 10px;
}
.tips span:first-of-type {
	margin-right: 16px;
}

.svg-container {
	padding: 6px 5px 6px 15px;
	color: #889aa4;
	vertical-align: middle;
	width: 30px;
	display: inline-block;
}

.bagBg .title-container {
	position: relative;
}
.title {
	font-size: 26px;
	color: #000;
	margin: 0px auto 0 auto;
	font-weight: 400;
}

.show-pwd {
	position: absolute;
	right: 10px;
	top: 7px;
	font-size: 16px;
	color: #889aa4;
	cursor: pointer;
	user-select: none;
}
.bagBg {
	/* background: url("../../assets/images/background.png") no-repeat; */
	background-size: 100% 100%;
	width: 550px;
	position: fixed;
	right: 80px;
	top: 50%;
	transform: translate(0, -50%);
	height: 510px;
}
.colorKEOe {
	color: #999;
}
.colore {
	color: #000;
	text-align: right;
}
.tips {
	text-align: right;
}
.loginImg {
	position: fixed;
	left: 220px;
	top: 49%;
	transform: translate(0, -50%);
}
.loginImg img {
	width: 400px;
	height: 300%;
}
.forgetPadd {
	width: 318px;
	margin: auto;
}
.borderM {
	border-bottom: 1px solid rgba(22, 15, 83, 0.2);
	margin-bottom: 20px;
}
.bagBg1 {
	height: 641px;
}
.bagBg ::v-deep .el-input__inner {
	border: none;
	border-radius: 0;
	border-bottom: 1px solid rgba(22, 15, 83, 0.2);
	padding-left: 0;
}
.bagBg ::v-deep .el-form-item__label {
	padding: 0;
}
.colornear {
	color: red;
	margin-left: 3px;
}
.fLogin {
	display: inline-block;
	text-align: right;
	width: 100%;
	padding: 30px 0 30px 0;
	font-size: 15px;
	text-decoration: underline;
	cursor: pointer;
}
.el-select {
	width: 100%;
}
@media screen and (max-width: 800px) {
	.bagBg {
		width: 100%;
		right: 0px;
	}
	.bagBg .login-form {
		width: 80%;
		padding-left: 10%;
		padding-right: 10%;
	}
}
</style>

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
	return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
	const valid_map = ["admin", "editor"];
	return valid_map.indexOf(str.trim()) >= 0;
}
export function validateEqNotNull(rule, value, callback) {
	if (value === "") {
		callback(new Error("输入不能为空！"));
	} else if (value != this.password) {
		console.log(value + "#");
		console.log(this.password + "!");
		callback(new Error("两次输入不一致"));
	} else {
		callback();
	}
}
export function validateNull(rule, value, callback) {
	if (value === "") {
		callback(new Error("不能为空！"));
	} else {
		callback();
	}
}
export function identityCard(rule, value, callback) {
	const reg =
		/^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[X])$)$/;
	if (value == "" || value == undefined || value == null) {
		callback();
	} else {
		if (!reg.test(value) && value != "") {
			callback(new Error("请输入正确的身份证号码"));
		} else {
			callback();
		}
	}
}
/* 是否手机号码*/
export function validatePhone(rule, value, callback) {
	const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
	if (value == "" || value == undefined || value == null) {
		callback();
	} else {
		if (!reg.test(value) && value != "") {
			callback(new Error("请输入正确的电话号码"));
		} else {
			callback();
		}
	}
}
/* 是否邮箱*/
export function validateEMail(rule, value, callback) {
	const reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
	if (value == "" || value == undefined || value == null) {
		callback();
	} else {
		if (!reg.test(value)) {
			callback(new Error("请输入正确的邮箱地址"));
		} else {
			callback();
		}
	}
}

<template>
	<div id="app">
		<!-- <ScaleBox
        :width="2230"
        :height="1080"
        bgc="transparent"
        :delay="100"
        :isFlat="false"
      >
      <router-view />
      </ScaleBox> -->
		<router-view />
	</div>
</template>

<script>
import ScaleBox from "vue2-scale-box";
export default {
	name: "App",
	components: {
		ScaleBox,
	},
};
</script>

<style>
html,
body {
	width: 100%;
}

body {
	min-width: 1200px;
	margin: 0;
	padding: 0;
}

@media screen and (max-width: 800px) {
	body {
		min-width: 0px;
		width: 100%;
		margin: 0;
		padding: 0;
	}
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

* {
	font-size: 12px;
	font-family: "Microsoft YaHei", SimSun, "\5b8b\4f53", sans-serif;
}

ol,
ul {
	list-style: none;
}

span,
a {
	cursor: pointer;
}

a {
	text-decoration: none;
	background-color: transparent;
	outline: none;
	cursor: pointer;
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
	-webkit-text-decoration-skip: objects;
}

img {
	vertical-align: middle;
}

.flex {
	display: flex;
}

.align_items_c {
	align-items: center;
}

.align_items_e {
	align-items: end;
}

.align_items_s {
	align-items: start;
}

.justify_content_c {
	justify-content: center;
}

.justify_content_sb {
	justify-content: space-between;
}

.justify_content_end {
	justify-content: flex-end;
}

.justify_content_end {
	justify-content: flex-end;
}

.direction_column {
	flex-direction: column;
}

.margin_auto {
	margin: 0 auto;
}

.ellipsis_one {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.ellipsis_multiple {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}

.main {
	position: absolute;
	top: 90px;
	left: 50%;
	transform: translateX(-50%);
}

.main_r {
	width: calc(100% - 210px);
}

.aside .el-menu {
	height: 100%;
}

.card_box .el-card__header {
	background-color: #f9f9f9;
	padding: 10px 15px;
}

.other_main {
	width: 100%;
	top: 70px;
	height: calc(100% - 70px);
}

.card_title {
	font-size: 20px;
	color: #000;
	font-weight: bold;
}

.card_title i {
	margin-right: 10px;
	font-size: 15px;
}

.other_page_r {
	height: 90vh;
	margin-left: 10px;
	margin-top: 10px;
}

.other_page_r .el-card {
	height: 100%;
}

.custom_btn_width_100 {
	width: 100px;
}

.custom_btn .el-button {
	padding: 8px 20px;
}
.custom_input_80 {
	width: 80px !important;
}
.custom_input_100 {
	width: 100px !important;
}
.custom_input_120 {
	width: 120px !important;
}
.custom_input_150 {
	width: 150px !important;
}

.custom_input_160 {
	width: 160px !important;
}

.custom_input_170 {
	width: 170px !important;
}

.custom_input_180 {
	width: 180px !important;
}

.custom_input_190 {
	width: 190px !important;
}

.custom_input_200 {
	width: 200px !important;
}
.custom_input_300 {
	width: 300px !important;
}
.custom_input_350 {
	width: 350px !important;
}
.custom_input_400 {
	width: 400px !important;
}

.table_box {
	margin-top: 20px;
}

.table_footer {
	margin-top: 20px;
}

.pagination-container {
	padding: 0 !important;
}

.pointer {
	cursor: pointer;
}

.margin_left_10 {
	margin-left: 10px;
}

.margin_left_20 {
	margin-left: 20px;
}

.margin_top_20 {
	margin-top: 20px;
}

.margin_top_40 {
	margin-top: 40px;
}

.font_weight_bold {
	font-weight: bold;
}
.hidden_scroll::-webkit-scrollbar {
	display: none;
}
.logo-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.logo-uploader .el-upload:hover {
	border-color: #409eff;
}

.logo-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 100px;
	height: 100px;
	line-height: 100px !important;
	text-align: center;
}

.logo {
	width: 100px;
	height: 100px;
	display: block;
}
.addLabelCategoryDialog .el-dialog {
	width: 600px;
	background: #ffffff;
	box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.29);
	border-radius: 10px 10px 10px 10px;
	opacity: 1;
}
</style>

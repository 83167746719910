<template>
	<div class=" ">
		<el-card>
			<div slot="header" class="card_title"> 快讯模版录入 </div>
			<div class="add_main hidden_scroll">
				<div class="sub_content">
					<div class="">
						<el-form
							label-position="right"
							label-width="90px"
							:model="formData"
							:rules="rule"
							ref="formData"
						>
							<el-form-item label="模版内容" prop="template">
								<el-input
									type="textarea"
									placeholder="请粘贴模版内容"
									autosize
									v-model="template"
									@input="templateChanged($event)"
								>
								</el-input>
							</el-form-item>
							<el-form-item label="中文标题" prop="title">
								<el-input
									placeholder="可从模版中自动提取"
									v-model="formData.title"
								>
								</el-input>
							</el-form-item>
							<el-form-item label="中文内容" prop="content">
								<el-input
									type="textarea"
									placeholder="可从模版中自动提取"
									autosize
									v-model="formData.content"
								>
								</el-input>
							</el-form-item>

							<el-form-item label="允许繁体">
								<el-radio v-model="formData.prohibit_tran" label="1"
									>禁止</el-radio
								>
								<el-radio v-model="formData.prohibit_tran" label="0"
									>允许</el-radio
								>
							</el-form-item>
							<el-form-item label="允许推送tg">
								<el-radio
									v-model="formData.allow_tgpush"
									label="0"
									:disabled="$route.query.type == 'detail'"
									>禁止</el-radio
								>
								<el-radio
									v-model="formData.allow_tgpush"
									label="1"
									:disabled="$route.query.type == 'detail'"
									>允许</el-radio
								>
							</el-form-item>
							<el-form-item>
								<div
									class="flex justify_content_c align_items_c"
									style="margin-left: -80px; margin-top: 40px"
								>
									<el-button
										type="primary"
										class="custom_btn_width_100"
										@click="submit('formData')"
										v-if="$route.query.type != 'detail'"
										>保存</el-button
									>
									<el-button class="custom_btn_width_100" @click="clearFormAdd"
										>清空</el-button
									>
								</div>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</el-card>
		<div v-if="loading" class="loading-overlay">
			<!-- loading遮罩内容 -->
			<div class="spinner"></div>
		</div>
	</div>
</template>

<script>
import { myMixin } from "@/utils/mixin.js";
import { addInformation } from "@/api/article";
import { validateNull } from "@/utils/validate";

export default {
	mixins: [myMixin],
	name: "",
	data() {
		return {
			props: { multiple: false },
			rule: {
				title: [{ validator: validateNull, trigger: "blur", required: true }],
				content: [{ validator: validateNull, trigger: "blur", required: true }],
			},
			template: "",
			loading: false,
			formData: {
				author_id: "1632",
				category_id: "2",
				special_id: "",
				label_id: 0,
				ticket_id: "9,13,27,29,44,46,",
				title: "",
				title_en: "",
				illustrate: "",
				illustrate_en: "",
				url: "",
				source_id: "0",
				source_type: "66",
				language: "1",
				pic: "",
				content: "",
				content_en: "",
				status: 1,
				type: "2",
				prohibit_tran: "0",
				is_home: "0",
				is_recommend: "0",
				is_top: "0",
				release_time: "",
				score: "100",
				is_push: "",
				is_delete: 0,
				allow_tgpush: "1",
			},
		};
	},
	watch: {},
	mounted() {
		// 创建自定义按钮
	},
	created() {},
	methods: {
		templateChanged($event) {
			$event = $event.trimStart();
			// 使用正则表达式判断内容是否包含换行符
			const parts = $event.split(/\r?\n/).filter((line) => line.trim() !== "");
			const date = new Date();
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, "0");
			const day = date.getDate().toString().padStart(2, "0");
			this.formData.title = parts[0];
			var content =
				"<p>MetaEra 消息，" +
				month +
				"月" +
				day +
				"日，" +
				(parts.length > 1 ? parts[1] : "") +
				"</p>";
			for (let index = 2; index < parts.length; index++) {
				const element = parts[index];
				content += "<p>" + element + "</p>";
			}
			console.log(parts);
			var text = content;
			const index = content.indexOf("（来源");
			if (index !== -1) {
				// 获取从找到的位置到文本结尾的子字符串
				const beforeText = content.substring(0, index);
				const afterText = content.substring(index);

				// 包裹找到的位置及其后的文字
				text = `${beforeText}<span style="color: rgb(136, 136, 136);">${afterText}</span>`;
			}
			this.formData.content = text;
		},
		// 新增资讯提交
		submit(formName) {
			let formData = this.formData;

			if (
				(formData.title && formData.title.length > 500) ||
				(formData.title_en && formData.title_en.length > 500)
			) {
				this.$message.error("标题过长，请输入500以内内容");
				return;
			} else if (formData.illustrate && formData.illustrate.length > 500) {
				this.$message.error("摘要过长，请输入500以内内容");
				return;
			}
			this.$refs[formName].validate((valid) => {
				const that = this;
				if (valid) {
					that.formData.special_id = "";
					console.log(formData);
					this.loading = true;
					addInformation(formData)
						.then((res) => {
							this.loading = false;
							if (res.code == 200) {
								that.$message.success("操作成功！");
							} else {
							}
						})
						.catch((error) => {
							this.loading = false;
						});
				} else {
					that.$message.error("缺少必填参数，请根据提示填写！");
					that.ticket_id = that.ticket_id.split(",");
					return false;
				}
			});
		},
		clearFormAdd() {
			this.formData.title = "";
			this.formData.content = "";
			this.formData.prohibit_tran = "0";
			this.formData.allow_tgpush = "1";
		},
	},
};
</script>

<style scoped>
.main {
	width: calc(100% - 40px);
}
.upload_img_div {
	display: flex;
}
.upload_img_del {
	margin-left: -15px;
	margin-top: -15px;
}
.upload_img_del img {
	width: 30px;
	height: 30px;
}
.add_main {
	width: 100%;
	height: 100vh;
	overflow-y: scroll;
	margin: 0 auto;
}

.sub_content {
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 30px;
}
</style>
<style></style>
<style></style>
<style>
.el-card__body,
.el-main {
	padding-top: 0 !important;
	padding: 0 !important;
}
</style>
<style scoped>
.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner {
	/* 样式可以根据需求自行调整 */
	border: 4px solid rgba(255, 255, 255, 0.3);
	border-radius: 50%;
	border-top: 4px solid #fff;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
}

.card_title {
	text-align: center;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>

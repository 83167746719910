<template>
	<div id="loyout">
		<!-- <el-container> -->
		<layoutHeader></layoutHeader>
		<router-view></router-view>
		<!-- <layoutAside></layoutAside>
        <el-container>
          <layoutHeader></layoutHeader>
          <el-main id="elmain">
            <transition name="main" mode="out-in">
              <router-view></router-view>
            </transition>
          </el-main>
          <el-footer>
            <Bottom></Bottom>
          </el-footer>
        </el-container>
      </el-container> -->
	</div>
</template>

<script>
import layoutHeader from "./header/header";

export default {
	name: "layout",
	components: {
		layoutHeader,
	},
};
</script>

<style></style>

import Cookies from 'js-cookie'

const TokenKey = 'wwy-treeadmin-users'
// const userKey = 'wwy-vecoadmin-users'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserToken() {
  return Cookies.get(TokenKey)
}
